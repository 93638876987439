@font-face {
  font-family: 'PhillipsMuler';
  src: url(./fonts/phillips_muler_signature-webfont.woff) format('woff');
}

@font-face {
  font-family: 'Brandon';
  src: url(./fonts/brand_ssv/Brandon_reg.otf) format('opentype');
}

body {
  margin: 0;
  font-family: 'Brandon';
  line-height: 1.5;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',*/
  /*'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content,
.title-item {
  opacity: 0;
  transform: translateY(1rem);
}

.wedding-party-item {
  opacity: 0;
  transform: translateY(1rem);
}

.title-item {
  display: inline-block;
}

.wedding-party-enter-done,
.section-title-enter-done {
  transform: translateY(0);
  opacity: 1;
}

.section-subtitle-enter-done {
  transform: translateY(0);
  opacity: 1;
}

.content-enter-done {
  transform: translateY(0);
  opacity: 1;
}

.menu,
.menu-container .menu-items {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: 1s;
}

.menu,
.menu-container .menu-home {
  opacity: 1;
}

.menu-container-enter-done .menu-items {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: 1s;
}

.slick-prev {
  left: 5% !important;
  height: 40px;
  width: 40px;
  z-index: 1;
}
.slick-next {
  right: 5% !important;
  height: 40px;
  width: 40px;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.slick-next:before,
.carousel-next:before {
  content: '›' !important;
}

.slick-prev:before,
.carousel-prev:before {
  content: '‹' !important;
}

.carousel-prev,
.carousel-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.carousel-prev:before,
.carousel-next:before {
  font-family: 'slick';
  line-height: 1;
  opacity: 0.75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.carousel-prev:before,
.carousel-next:before,
.slick-next:before,
.slick-prev:before {
  font-size: 50px !important;
}

.polaroid {
  background: #fff;
  padding: 1rem;
  box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);
  transform: translateY(-18px);
  filter: none !important;
}

.flip-card-enter-done,
.back-flip-card-enter-done,
.thank-you-card-enter-done {
  visibility: visible !important;
  opacity: 1 !important;
  transition: opacity 0.75s 0.25s !important;
}

.flip-card-exit-done,
.back-flip-card-exit-done,
.thank-you-card-exit-done {
  visibility: hidden !important;
  opacity: 0 !important;
  transition: visibility 0s 2s, opacity 0.75s !important;
}
